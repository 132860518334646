import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { ICountUserAdverstises, IUser } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private cookieService = inject(CookieService);
  private router = inject(Router);

  UserSubject = new Subject<void>();
  SendSubject = new Subject<void>();

  private myUser: IUser = {} as IUser;
  countUser: ICountUserAdverstises = {} as ICountUserAdverstises;
  myFilter: any = {};

  get myself() {
    return this.myUser;
  }

  set myself(user: IUser) {
    this.myUser = user;
  }

  get filter() {
    return this.myFilter;
  }

  set filter(filter: any) {
    this.myFilter = filter;
  }

  get count_user() {
    return this.countUser;
  }

  set count_user(obj: ICountUserAdverstises) {
    this.countUser = obj;
  }

  watchUser() {
    return this.UserSubject.asObservable();
  }

  unwatchUser() {
    // Não chame o método unsubscribe() diretamente em um Subject
    // this.UserSubject.unsubscribe();
    this.UserSubject.complete(); // Em vez disso, use o método complete() para indicar que não haverá mais emissões
  }

  changeUser(): void {
    this.UserSubject.next();
  }

  watchSend() {
    return this.SendSubject.asObservable();
  }

  unwatchSend() {
    this.SendSubject.unsubscribe();
  }

  // changeSend(data: any): void {
  //   this.SendSubject.next(data);
  // }

  get token() {
    if (this.cookies) {
      return this.cookieService.get('token');
    } else {
      return this.isSessionStorageAvailable()
        ? sessionStorage.getItem('token')
        : null;
    }
  }

  /**
   * Função para setar o token no cookie
   * @param token Token que vem da API
   * @param keep Se true, o cookie expira em 60 dias, se false, o cookie expira quando o browser é fechado
   * @return void
   *
   * @author Kauã Landi
   */
  setToken(token: string, keep = false): void {
    if (this.cookies) {
      this.cookieService.set(
        'token',
        token,
        keep ? 60 : undefined,
        '/',
        undefined,
        this.ssl,
        'Strict'
      );
    } else if (this.isSessionStorageAvailable()) {
      sessionStorage.setItem('token', token);
    }
  }

  get cookies() {
    return (
      this.isLocalStorageAvailable() &&
      localStorage.getItem('cookies') === 'true'
    );
  }

  set cookies(value: boolean) {
    if (this.isLocalStorageAvailable()) {
      localStorage.setItem('cookies', value.toString());
    }
  }

  logout() {
    this.setToken('', false);

    this.cookieService.deleteAll();
    if (this.isSessionStorageAvailable()) {
      sessionStorage.removeItem('token');
      sessionStorage.clear();
    }

    this.router.navigate(['/login']);
  }

  get ssl() {
    return location.protocol === 'https:';
  }

  private isLocalStorageAvailable(): boolean {
    return typeof localStorage !== 'undefined';
  }

  private isSessionStorageAvailable(): boolean {
    return typeof sessionStorage !== 'undefined';
  }
}
