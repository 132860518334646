import { FirebaseOptions } from 'firebase/app';

export const environment = {
  production: false,
  base_url: 'https://nip.noclaf.com.br/',
  page_size: 2,
  key_map: 'AIzaSyAl_ybMiYIhvMowd2FnEDVg9sxOanG-__0',
  firebaseConfig: {
    apiKey: 'AIzaSyDumvSus4lfXQadOBRqpELff_xE095hCjw',
    authDomain: 'nip-c5f24.firebaseapp.com',
    projectId: 'nip-c5f24',
    storageBucket: 'nip-c5f24.appspot.com',
    messagingSenderId: '524048063850',
    appId: '1:524048063850:web:e4198e1effd1427188d913',
    measurementId: 'G-HEBQXX1C5Q',
  } as FirebaseOptions,
};
